import { graphql } from "gatsby";
import * as React from "react"
import { useTranslation } from "react-i18next";
import DefaultLayout from "../layout";

import * as styles from "./index.module.scss"

const NotFoundPage = () => {
  const {t} = useTranslation();

  return (
    <DefaultLayout title={t("error404title")}>
      <div className={styles.layoutBox + " " + styles.centerBox}>
        <h1>{t("error404title")}</h1>
        <p>{t("error404text")}</p>
      </div>
    </DefaultLayout>
  )
}

export default NotFoundPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;